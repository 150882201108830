/* @import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Delius+Swash+Caps&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sono&display=swap');

html {
  margin: 0;
  padding: 0;
 box-sizing: border-box;
}

/* *:before,
*:after {
 box-sizing: border-box;
} */

body {
  display: flex;
  /* height: 100%;
  width: 100%; */
  max-width: 1920px;
  margin: 0 auto;
  flex-direction: column;
    /* font-family: "Delius Swash Caps", cursive; */
  font-family: 'Sono', sans-serif;
  /* background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("../src/logos/jinglebackground.png");
  background-position: center;
  background-repeat: repeat-x;
  background-size: cover; */

  /* color: #fff; */
}

.main{
  min-height: 45vh;
  font-size: 1.2rem;
  overflow: hidden;
  background-color: #f5fbff;
  padding: 1rem 1rem 1rem 1rem;

  /* border: 8px dashed #4CAf50; /* Green */
border-radius: 15px;
/* margin: 0 auto; */


  /* background-image: url(); */
  /* border-image: url('../src/logos/logo.svg');
  border-image-width: 100%;
  /* border-image-outset:%; */
  /* border-image-repeat: repeat; */



  /* background-blend-mode: exclusion; */

  /* width: 100%; */
  /* height: 100%; */
  /* min-height: 100%; */
}

h1, h2, h3, h4, h5{
  /* font-size: 1.5rem; */
      color: #0DCAF0;
      text-align: center;
      /* font-size: 1.2rem; */
        font-family: "Pacifico", cursive;
}
/* pages */

/* Messages */

.bubble {
  border-radius: 15px;
  margin: 10px;
  padding: 15px;
  position: relative;
  width: 70%;
}

.bubble--left {
  background-color: #f0f0f0;
  float: left;
}

.bubble--right {
  background-color: #0b93f6;
  color: white;
  float: right;
}


.wrapper{
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content: center;
  align-items: center;
  align-self: center;

  margin-left: 1rem;
  margin-right: 1rem;
  /* padding-top: 5rem; */
  /* padding-bottom: 5rem; */
}

.nav-link:hover{
  color: #fcecb3;
  opacity: 0.8
}

.welcomeText .nav-link{
  /* border: solid black 1px; */
  display: inline-block;
  animation: heartbeat 2s infinite;

}
.welcomeText h3{
  /* border: solid black 1px; */
/* text-align: left; */
}

.welcomeText .nav-link:hover{
  color: #00cba9;
  opacity: 0.8
}


.resetlinks .nav-link:hover{
  color: #00cba9;
  opacity: 0.8
}



/* ABOUT */

.aboutus h3{
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;

}
.accordeonTitle{
  display: flex;
  align-items: center;
  /* border: 1px solid green; */


  /* margins */

  margin-top: 1rem;
  margin-bottom: 1rem;

  /* paddings */

  padding-left: 2rem;
  padding-right: 2rem;

  padding-top: 1rem;
  padding-bottom: 1rem;
}

.accordeonTitle h4{
    /* font-family: 'Sono'; */
    font-size: 1.7rem;
    text-align: left;
    /* color: #00cba9; */
    /* border: 1px solid green; */
    width: 100%

}

.content{
  /* color: #006E7F */
  white-space: "pre-wrap";
  /* text-align: justify; */
}

.content, p{

  padding: 1rem 0.5rem 1rem 0.5rem;
  /* padding-right: 3rem; */
  /* text-align: justify; */
  white-space: "pre-wrap";
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
}

.plussign{
  /* border: 1px solid green; */
  color: #00cba9;
  font-size: 3rem;
  cursor: pointer;
}

.minussign{
  /* border: 1px solid green; */
  color: #00cba9;
  font-size: 3rem;
  cursor: pointer;
}

.accordeonContainer{
  margin-bottom: 1rem;

}

.inactiveAccordeon{
  /* background-color: #fcecb3; */
  border-radius: 10%;
}

.activatedAccordeon{
  border-radius: 3%;
  /* background-color: #fcecb3; */
}

/* NAV */
.wavynavcontainer {
  /* font-family: 'Sono', sans-serif; */
    /* font-family: "Pacifico", cursive; */
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #00cba9;
  color: #fff;
  /* margin-bottom: 1rem; */
  padding: 1.1rem 1rem 1.1rem 1rem
}

.navlinks{
  display: flex;
  flex-direction: column;
  gap:1rem;
  /* justify-content: center; */
  /* align-items: center; */
}

.logocontainer{
  /* order: -1; */
}

.navlinks .nav-link{
  /* margin-left: 1rem; */
  /* margin-right: 1rem; */
  /* text-align: center; */
}


/* FOOTER */

.footer{
  /* font-family: 'Sono', sans-serif; */
  font-size: 1.4rem;
  font-weight: 600;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  align-content: center;
  text-align: center; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

  /* align-self: baseline; */
  /* align-content: center; */
  /* text-align: center; */

  /* background-color: #0099ff; */
    color: #fff;

    padding: 1.1rem 1rem 1.1rem 1rem

    /* margin-top: 1rem; */
    /* padding-top:3rem; */
    /* padding-bottom: 3rem; */
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
    /* border: 1px solid yellow */
}


.footerlinks{
  display: inline-block;
  align-self: flex-end;
  /* border: 1px solid green */
}


.footersocial{
  /* display: flex;
  justify-content: center; */
  display: flex;
  align-self: flex-end;
  /* flex-direction: column; */
  /* border: 1px solid green */
}


.footersocial  .nav-link{
  margin-left: 0.5rem;
  display: inline-block;
  align-self: flex-end;
  /* border: 1px solid blue */
}



/* DASHBOARD */
.dashboardcontainer{
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

span{
  color: #006E7F;
  font-style: italic;
}

/* CARDS */
.card{
  margin: 1rem 1rem 1rem 1rem;
  /* background: #a2d9ff */
}

.card-img-top{
  margin-top: 0.5rem;
}

.contactsection > p{
  text-align: center;
}

/* PRODUCT IMAGES */

.productimg{

  display: inline-block;
width:100% !important;
height:100% !important;
max-width: 200px !important;
max-height: 200px!important;
margin-top: 1rem;
}

.productimgview{
  width:100% !important;
  height:90% !important;
  max-width: 500px !important;
  max-height: 500px!important;
  object-fit: contain;
}

/* .immgprev{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
} */


/* FORM LABEL */

label {
  margin-top: 1rem;
}


.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.formErrorMsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

/* SEARCH BAR */

.searchbar{
  margin-top: 1rem;
  margin-bottom:1rem;
  /* text-align: center; */
}


/* JINGKLE LOGO */
.logo{
  height: auto;
    margin-left: 1rem;
    margin-right: 1rem;
    /* max-height: 111px; */
    max-width: 86px;
}

/* IMAGES PRV */

.imageprevs{
  height: 100%;
  width: auto;
  max-height: 150px;
  max-width: 150px;
}

.imageprevDIV{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.closebutton{
  fill: red;
}

.closebutton:hover{
  fill:black;
  opacity: 0.5;
  cursor: pointer;
}

/* SPINNER  */

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh */
}

.loadingSpinner {
  width: 100px;
  height: 100px;
  background-image: url("../src/logos/logo3_512.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* margin-top: 10rem; */
  /* margin-bottom: 10rem; */
  /* border: 8px solid; */
  /* border-color: #000 transparent #555 transparent; */
  /* border-radius: 50%; */
  animation: spin 1.2s linear infinite;
}

.notification-icon {
  left: 25%;
  position: absolute;
  top: 11%;
  transform: translate(-50%,-50%);
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 0
  }
  50% {
    transform: rotate(-60deg);
    opacity: 0.5
  }
  100% {
    transform: rotate(60deg);
    opacity: 1
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

  @media screen and (min-device-width : 350px) and (max-device-width : 920px) {

    .logo{
      max-width: 45px;
      margin-left: 0.3rem;
    }

    /* .nav-link{
      font-size: 0.8rem;
    } */
  }

/* @media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
} */
